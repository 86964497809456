// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const CertificationCard = ({ image, children, classNames }) => {
  const Styles = { ...Style, ...classNames };

  return (
    <div className={Styles.certificationCard}>
      <div className={Styles.imageContainer}>
        <img className={Styles.imageStyle} src={image} />
      </div>
      <div className={Styles.cardContent}>{children}</div>
    </div>
  );
};

export default CertificationCard;
