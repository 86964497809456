// TODO: REFACTORING
import React from 'react';
import Chip from '../Chip';
import { translate as t } from '../../utils/translate';
import * as Styled from './AircraftAutonomy.styles';
import AircarftMap from '@components/AircraftMapLocalization/AircraftMapLocalization';

const AircraftAutonomy = ({
  title,
  titleTag = 'p',
  reachInfo,
  chipText,
  latitude,
  longitude,
  chipSvgSource,
  lang,
}) => {
  const TEXTS = {
    AIRCRAFT_LOCATION: t('aircraftLocation'),
    AIRCRAFT_VELOCITY: t('aircraftVelocity'),
    AIRCRAFT_VELOCITY_DETAIL: t('aircraftVelocityDetail'),
  };
  return (
    <Styled.AircraftAutonomy>
      <Styled.Title as={titleTag}>{title}</Styled.Title>
      <Styled.Content>
        <Chip text={`${TEXTS.AIRCRAFT_LOCATION}`} svgSource={chipSvgSource} />
        <Styled.Reach>
          {TEXTS.AIRCRAFT_VELOCITY}
          {` ${reachInfo} `}
          {TEXTS.AIRCRAFT_VELOCITY_DETAIL}
        </Styled.Reach>
        <Styled.ImageContainer>
          <AircarftMap
            dimensions={{ width: '100%', height: '100%' }}
            info={{ reachInfo, latitude, longitude }}
            lang={lang}
          />
        </Styled.ImageContainer>
      </Styled.Content>
    </Styled.AircraftAutonomy>
  );
};

export default AircraftAutonomy;
