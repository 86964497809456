//TODO: REFACTORING
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api';
import mapStyle from './MapStyle.json';
import { apiKeyGoogleMaps } from '@utils/constants';
import { getLangParam } from '@utils/translate';

import { useCurrentLang } from '@utils/translate';

function AircraftMapLocalization({ dimensions, info }) {
  const [circle, setCicle] = useState(null);
  const [map, setMap] = useState(null);
  const [lang, setLang] = useState(null);
  const currentLang = useCurrentLang();

  useEffect(() => {
    const language = getLangParam(currentLang);

    if (language) setLang(language.replace('_', '-'));
  }, []);

  useEffect(() => {
    if (circle) {
      map.fitBounds(circle);
    }
  }, [circle]);

  if (!lang) return null;

  return (
    <LoadScript googleMapsApiKey={apiKeyGoogleMaps} language={lang}>
      <GoogleMap
        mapContainerStyle={{
          width: dimensions.width,
          height: dimensions.height,
        }}
        center={{
          lat: info.latitude,
          lng: info.longitude,
        }}
        zoom={4}
        onLoad={map => {
          setMap(map);
        }}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          minZoom: 3,
          maxZoom: 12,
          styles: mapStyle,
        }}
      >
        <Circle
          center={{
            lat: info.latitude,
            lng: info.longitude,
          }}
          radius={info.reachInfo * 1000}
          options={{
            strokeColor: '#01BEA4',
            strokeWeight: 3,
            fillColor: '#1aa086',
            fillOpacity: 0.2,
          }}
          onLoad={circle => {
            setCicle(circle.getBounds());
          }}
        />
      </GoogleMap>
    </LoadScript>
  );
}

AircraftMapLocalization.propTypes = {
  dimensions: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

//export default React.memo(AircraftMapLocalization);
export default AircraftMapLocalization;
