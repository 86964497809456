// react/third-part modules
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

// component modules
import * as Styled from './AircraftForSaleForm.style';
import SpinningWheel from '@components/SpinningWheel';

// utils
import { translate as t } from '@utils/translate';
import { apiHost, aircraftSalesRequest } from '@utils/constants';

import useRequest from '@utils/useRequest';

const AircraftForSaleForm = ({ aircraftId }) => {
  const [loading, setLoading] = useState(false);
  const [responseInfo, setResponseInfo] = useState({
    msg: '',
    type: '',
  });
  const TEXTS = {
    TITLE: t('aircraftSaleFormTitle'),
    SUBTITLE: t('aircraftSaleFormSubtitle'),
    BUTTON_LABEL: t('send'),
    NAME: t('fullName'),
    EMAIL: t('email'),
    PHONE: t('phone'),
    MESSAGE: t('message'),
    REQUIRED_FIELD: t('requiredField'),
    INVALID_EMAIL: t('invalidEmail'),
    INVALID_PHONE: t('invalidPhone'),
    RESPONSE_FAILURE_MSG: t('aircraftSaleRequestFailure'),
    RESPONSE_SUCCESS_MSG: t('aircraftSaleRequestSuccess'),
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required(TEXTS.REQUIRED_FIELD),
    phone: yup
      .string()
      .required(TEXTS.REQUIRED_FIELD)
      .trim()
      .matches(/^\+[0-9 \-]+$/i, {
        message: TEXTS.INVALID_PHONE,
      }),
    email: yup
      .string()
      .required(TEXTS.REQUIRED_FIELD)
      .trim()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, {
        message: TEXTS.INVALID_EMAIL,
      }),
    message: yup.string().required(TEXTS.REQUIRED_FIELD),
  });
  const { register, handleSubmit, errors } = useForm({
    validationSchema: validationSchema,
    mode: 'onChange',
  });

  const onSubmit = async ({ email, name, phone, message }) => {
    setLoading(true);

    const payload = {
      aircraftSaleRequest: {
        name: name,
        emailAddress: email,
        phoneNumber: phone,
        message: message,
        aircraftId: aircraftId,
      },
    };
    const url = apiHost + aircraftSalesRequest;

    try {
      const { data } = await useRequest({
        url: url,
        method: 'post',
        useSession: false,
        data: JSON.stringify(payload),
      }).catch(err => {
        console.error(err);
        setResponseInfo({ msg: TEXTS.RESPONSE_FAILURE_MSG, type: 'error' });
      });

      if (data.result.status === 0) {
        setResponseInfo({ msg: TEXTS.RESPONSE_SUCCESS_MSG, type: 'success' });
      } else {
        setResponseInfo({ msg: TEXTS.RESPONSE_FAILURE_MSG, type: 'error' });
      }
    } catch (e) {
      console.error(e);
      setResponseInfo({ msg: TEXTS.RESPONSE_FAILURE_MSG, type: 'error' });
    }

    setLoading(false);
  };

  return (
    <Styled.Container>
      {loading ? (
        <Styled.RequestContainer>
          <SpinningWheel />
        </Styled.RequestContainer>
      ) : responseInfo && responseInfo.msg ? (
        <Styled.RequestContainer>
          <Styled.ResponseInfo type={responseInfo.type}>
            {responseInfo.msg}
          </Styled.ResponseInfo>
        </Styled.RequestContainer>
      ) : null}

      <Styled.Title>{TEXTS.TITLE}</Styled.Title>
      <Styled.SubTitle>{TEXTS.SUBTITLE}</Styled.SubTitle>
      <Styled.InputContent>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.InputRow>
            <Styled.InputWrapper>
              <Styled.InputText
                name="name"
                placeholder={TEXTS.NAME}
                ref={register}
              />
              {errors.name && <span>{errors.name.message}</span>}
            </Styled.InputWrapper>
            <Styled.InputWrapper>
              <Styled.InputText
                name="email"
                placeholder={TEXTS.EMAIL}
                ref={register}
              />
              {errors.email && <span>{errors.email.message}</span>}
            </Styled.InputWrapper>
            <Styled.InputWrapper>
              <Styled.InputText
                name="phone"
                placeholder={TEXTS.PHONE}
                ref={register}
              />
              {errors.phone && <span>{errors.phone.message}</span>}
            </Styled.InputWrapper>
          </Styled.InputRow>
          <Styled.InputRow>
            <Styled.InputWrapper>
              <Styled.InputTextArea
                name="message"
                placeholder={TEXTS.MESSAGE}
                ref={register}
              />
              {errors.message && <span>{errors.message.message}</span>}
            </Styled.InputWrapper>
          </Styled.InputRow>
          <Styled.Buttons>
            <Styled.UpdateButton type="submit" onClick={handleSubmit(onSubmit)}>
              {TEXTS.BUTTON_LABEL}
            </Styled.UpdateButton>
          </Styled.Buttons>
        </Styled.Form>
      </Styled.InputContent>
    </Styled.Container>
  );
};

export default AircraftForSaleForm;
