// TODO: REFACTORING
import React from 'react';
import Style from '../../style.module.css';
import SafetyCertificationItem from '../SafetyCertificationItem';

const SafetyCerts = ({ title, items, short }) => {
  const { scFirstRender, scSecRender, scThirdRender } = Style;

  return (
    <div className={Style.safetyCerts}>
      <p className={Style.scTitle}>{title}</p>
      {items.map((item, index) => {
        return (
          <SafetyCertificationItem
            item={item}
            key={index}
            short={short}
          ></SafetyCertificationItem>
        );
      })}
    </div>
  );
};

export default SafetyCerts;
