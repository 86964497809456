import AircraftSecurity from './AircraftSecurity';
export default AircraftSecurity;

import SafetyCertificationItem from './components/SafetyCertificationItem';
import SafetyCertificationList from './components/SafetyCertificationList';
import CountryChip from './components/CountryChip';
import AirCarrier from './components/AirCarrier';

export const Components = {
  SafetyCertificationItem,
  SafetyCertificationList,
  CountryChip,
  AirCarrier,
};
