// Third party libs/components
import React from 'react';
import P from 'prop-types';
import { connect } from 'react-redux';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// Styles
import Style from './style.module.css';

// Components
import TripDetailNewPath from '../TripDetailNewPath';

// Utils
import { translate as t } from '@utils/translate';

const AircraftQuotation = ({ aircraftInfo, image, name, type, regulament }) => {
  const form = {
    initialOriginId: aircraftInfo.cityId,
    initialSuggestion: aircraftInfo.cityName,
    initialDay: new Date(),
    onlyDestination: false,
  };

  const TEXTS = {
    TITLE: t('availabilityQuery'),
    DESCRIPTION: t('aircraftQuotationDescription'),
    AIRCRAFT: t('aircraft'),
    HELICOPTER: t('helicopter'),
  };

  return (
    <div className={Style.container}>
      <div className={Style.content}>
        <div className={Style.ModalDescription}>
          <h2 className={Style.title}>{TEXTS.TITLE}</h2>
          <p className={Style.description}>{TEXTS.DESCRIPTION}</p>
        </div>

        <div className={Style.aircraftData}>
          <img className={Style.imageData} src={image} alt={name} />
          <div className={Style.textData}>
            <p className={Style.type}>{TEXTS.AIRCRAFT}</p>
            <p className={Style.name}>{name}</p>
            <p className={Style.regulament}>{regulament}</p>
          </div>
        </div>
      </div>
      <TripDetailNewPath form={form} {...form} />
    </div>
  );
};

AircraftQuotation.propTypes = {
  aircraftInfo: P.object,
  image: P.string,
  name: P.string,
  type: P.string,
  regulament: P.string,
};

const mapStateToProps = state => ({
  aircraftInfo: state.searchSegments,
});

export default connect(mapStateToProps)(AircraftQuotation);
