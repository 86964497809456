// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import { translate as t } from '@utils/translate';
import { formatCurrency } from '@utils/currency';

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop - 200);

const ItemsList = ({ items }) => {
  return items.map(({ label, ref }, index) => (
    <li
      className={Style.navMenuItem}
      key={index}
      onClick={() => scrollToRef(ref)}
    >
      {t(label)}
    </li>
  ));
};

const NavMenu = ({
  items,
  visible,
  openQuotation,
  quotation,
  isSaleable,
  openRequestToBuy,
}) => {
  const TEXTS = {
    CHARTER_THIS_AIRCRAFT: t('charterThisAircraft'),
    GET_IN_TOUCH_BUTTON: t('getInTouch'),
    AVAILABLE_SEATS: t('availableSeats'),
    SELECT_AIRCRAFT: t('selectAircraft'),
  };

  const renderButtons = () => {
    if (isSaleable) {
      return (
        <div className={Style.buttonsWrapper}>
          <div className={Style.navMenuButton}>
            <button className={Style.dark} onClick={openRequestToBuy}>
              {TEXTS.GET_IN_TOUCH_BUTTON}
            </button>
          </div>
          <div className={Style.navMenuButton}>
            <button onClick={openQuotation}>
              {TEXTS.CHARTER_THIS_AIRCRAFT}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={Style.navMenuButton}>
          <button onClick={openQuotation}>{TEXTS.CHARTER_THIS_AIRCRAFT}</button>
        </div>
      );
    }
  };
  return (
    <div className={[Style.navMenu, visible ? Style.visible : ''].join(' ')}>
      <div className={[Style.navMenuContainer, 'container'].join(' ')}>
        <ul className={Style.navMenuList}>
          <ItemsList items={items} />
        </ul>
        {quotation === '' ? (
          renderButtons()
        ) : (
          <div className={Style.informationsContent}>
            <div className={Style.informationsQuotation}>
              <p className={Style.priceQuotation}>
                {formatCurrency({
                  value: quotation.price,
                  currency: quotation.currency,
                })}
              </p>
              <span className={Style.quantityPassengers}>
                {quotation.aircraft.quantityOfPassengers}
              </span>{' '}
              <span className={Style.quantityPassengers}>
                {TEXTS.AVAILABLE_SEATS}
              </span>
            </div>
            <div className={Style.navMenuButton}>
              <button>{TEXTS.SELECT_AIRCRAFT}</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavMenu;
