// TODO: REFACTORING
import React from 'react';
import Style from '../../style.module.css';
import { translate as t } from '@utils/translate';
import CertificationCard from '../CertificationCard';

const AirCarrier = ({ title, items }) => {
  const { acFirstRender, acSecRender, acThirdRender } = Style;
  const TEXTS = {
    OVER: t('over'),
    FLOWN: t('flown'),
  };
  return (
    <div className={Style.airCarrier}>
      <p className={Style.acTitle}>{title}</p>
      {items.map(({ image, firstRender, secRender, thirdRender }, index) => (
        <CertificationCard key={index} image={image}>
          <div
            className={acFirstRender}
            dangerouslySetInnerHTML={{ __html: firstRender }}
          ></div>
          <div
            className={acSecRender}
            dangerouslySetInnerHTML={{ __html: secRender }}
          ></div>
          {thirdRender && (
            <div
              className={acThirdRender}
            >{`${TEXTS.OVER} ${thirdRender} h ${TEXTS.FLOWN}`}</div>
          )}
        </CertificationCard>
      ))}
    </div>
  );
};
export default AirCarrier;
