// TODO: REFACTORING
import React from 'react';
import Style from '../../style.module.css';
import { useFlags } from '@utils/country';

const CountryChip = ({ name, abreviation }) => {
  const { publicURL } = useFlags(abreviation);

  return (
    <span className={Style.countryChip}>
      <img className={Style.countryIcon} src={publicURL} />
      <span className={Style.countryName}>{name}</span>
    </span>
  );
};

export default CountryChip;
