import styled from 'styled-components';

const Title = styled.p`
  font-size: 28px;
  line-height: 41px;
  font-weight: 700;
  color: #192a41;
  margin-bottom: 19px;
`;

export { Title };
