import styled from 'styled-components';

export const AircraftAutonomy = styled.section.attrs({
  className: 'container',
})`
  margin-bottom: 86px;

  @media (max-width: 1023px) {
    margin-bottom: 27px;
  }
`;

export const Title = styled.p`
  font-size: 28px;
  line-height: 41px;
  font-weight: 700;
  color: #192a41;
  margin-bottom: 25px;

  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 17px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 639px) {
    & > div {
      width: 100%;
    }
  }
`;

export const Reach = styled.p`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #828ea0;
  margin-bottom: 0;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 17px;
    order: 3;
    margin-top: 8px;
  }
`;

export const ImageContainer = styled.div`
  margin-top: 16px;
  height: 430px;
  flex-basis: 100%;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 0 50px #3334;

  @media (max-width: 768px) {
    min-height: 400px;
  }

  @media (max-width: 1365px) {
    height: 300px;
  }

  @media (max-width: 1023px) {
    height: 206px;
    margin-top: 8px;
  }
`;
