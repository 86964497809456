// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';
import * as Styled from './AircraftSecurity.styles';
import CertificationCard from './components/CertificationCard/CertificationCard';
import { translate as t } from '@utils/translate';
import CountryChip from './components/CountryChip';
import SafetyCertificationList from './components/SafetyCertificationList';

import { getExpirationTimeString } from '@utils/certifications.helper';

const AirCarrierCerts = ({ title, titleTag = 'p', items }) => {
  const { accFirstRender, accSecRender } = Style;
  const TEXTS = {
    LAST_CERTIFICATION: t('lastCertification'),
  };

  return (
    <div className={Style.airCarrierCerts}>
      <Styled.Title as={titleTag}>{title}</Styled.Title>
      {items.map(
        (
          {
            image,
            firstRender,
            secRender,
            thirdRender,
            isLive,
            expirationDate,
          },
          index
        ) => (
          <CertificationCard key={index} image={image}>
            <div
              className={accFirstRender}
              dangerouslySetInnerHTML={{ __html: firstRender }}
            />
            <div className={accSecRender}>
              {getExpirationTimeString(expirationDate)}
              <span style={{ color: '#192A41' }}> • </span>
              {`${TEXTS.LAST_CERTIFICATION} ${secRender[1]}`}
            </div>
          </CertificationCard>
        )
      )}
    </div>
  );
};

const PermissionCard = ({ name, countries, icon }) => {
  const {
    permissionCard,
    permissionCardItems,
    permissionCardRegion,
    permissionCardContainer,
    expandIcon,
    chipShowMore,
    showCountries,
  } = Style;

  const [open, setOpen] = useState(false);

  const hiddenCountries = countries.slice(3);
  const moreCountries = hiddenCountries && (
    <span
      onClick={() => setOpen(true)}
      className={chipShowMore}
    >{`${hiddenCountries.length} More`}</span>
  );

  return (
    <li className={permissionCardContainer}>
      <div>
        <p className={permissionCardRegion}>{t(name)}</p>
        <ul className={[permissionCard, open ? showCountries : ''].join(' ')}>
          {countries.map((item, index) => (
            <li className={permissionCardItems} key={index}>
              {' '}
              <CountryChip {...item} icon={icon} />{' '}
            </li>
          ))}
          {hiddenCountries.length > 0 && moreCountries}
        </ul>
      </div>
      <div className={expandIcon} dangerouslySetInnerHTML={{ __html: icon }} />
    </li>
  );
};

const FlightPermission = ({ title, description, icon, permissions }) => {
  return (
    <div className={Style.flightPermission}>
      <p className={Style.fpTitle}>{title}</p>
      <p className={Style.flightPermissionDesc}>{description}</p>
      <ul className={Style.flightPermissionItems}>
        {permissions.map((region, index) => (
          <PermissionCard icon={icon} key={index} {...region} />
        ))}
      </ul>
    </div>
  );
};

const AircraftSecurity = ({
  airCarrier,
  airCarrierCerts,
  safetyCerts,
  flightPermissions,
  flightPage,
  permissions,
  short,
  titleTag = 'p',
}) => {
  const TEXTS = {
    AIR_CARRIER: t('airCarrierCertsTitle'),
    SAFETY_CERT: t('safetyCertsTitle'),
    FLIGHT_PERMISSION: t('flightPermission'),
  };

  return (
    <section className={[Style.aircraftSecurity, 'container'].join(' ')}>
      {airCarrierCerts &&
        airCarrierCerts.items &&
        airCarrierCerts.items.length > 0 && (
          <AirCarrierCerts
            title={TEXTS.AIR_CARRIER}
            {...airCarrierCerts}
            titleTag={titleTag}
          />
        )}
      {safetyCerts && safetyCerts.items && safetyCerts.items.length > 0 && (
        <SafetyCertificationList
          title={TEXTS.SAFETY_CERT}
          short={short}
          {...safetyCerts}
        />
      )}
      {!flightPage && permissions.length > 0 && (
        <FlightPermission
          title={TEXTS.FLIGHT_PERMISSION}
          {...flightPermissions}
          permissions={permissions}
        />
      )}
    </section>
  );
};

export default AircraftSecurity;
