// TODO: REFACTORING
import React from 'react';
import Style from '../../style.module.css';
import { translate as t } from '@utils/translate';
import CertificationCard from '../CertificationCard';

import { getExpirationTimeString } from '@utils/certifications.helper';

const SafetyCertificationItem = ({ item, short, classNames }) => {
  const { image, firstRender, secRender, thirdRender, memberSince } = item;
  const Styles = { ...Style, ...classNames };
  const TEXTS = {
    EXPIRES_IN: t('expiresIn'),
    LAST_CERTIFICATION: t('lastCertification'),
    MEMBER_SINCE: t('memberSince'),
    YEARS: t('years'),
  };

  return (
    <CertificationCard image={image} classNames={classNames}>
      {!short && (
        <>
          <div
            className={Styles.scFirstRender}
            dangerouslySetInnerHTML={{ __html: firstRender }}
          ></div>
          <div className={Styles.scSecRender}>
            {getExpirationTimeString(item.expirationDate)}
            <span style={{ color: '#192A41' }}> • </span>
            {`${TEXTS.LAST_CERTIFICATION} ${secRender[1]}`}
          </div>
          <div className={Styles.scThirdRender}>
            {`${TEXTS.MEMBER_SINCE} ${memberSince}`}
          </div>
        </>
      )}
    </CertificationCard>
  );
};

export default SafetyCertificationItem;
